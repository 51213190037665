/* For use Div */
.row-col {
	display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
	margin-left:-15px;
	margin-right:-15px;
	//@include selfClearing;  

    &.justify-content-start {justify-content: flex-start!important;}
    &.justify-content-center {justify-content: center!important;}
    &.justify-content-end {justify-content: flex-end!important;}
    &.justify-content-around {justify-content: space-around!important;}
    &.justify-content-between {justify-content: space-between!important;}
    &.justify-content-evenly {justify-content: space-evenly!important;}

	& > [class*=column-]{
		@include flex-width(100%);		
		padding-left:15px;
		padding-right:15px; 
	}	
	@media (min-width:992px){
		.column-1 {@include flex-width(8.333333%);}
		.column-2 {@include flex-width(16.666667%);}	
		.column-3 {@include flex-width(25%);}	
		.column-4 {@include flex-width(33.333333%);}		
		.column-5 {@include flex-width(41.666667%);}
		.column-6 {@include flex-width(50%);}
		.column-7 {@include flex-width(58.333333%);}
		.column-8 {@include flex-width(66.666667%);}
		.column-9 {@include flex-width(75%);}
		.column-10 {@include flex-width(83.333333%);}
		.column-11 {@include flex-width(91.666667%);}
		.column-12 {@include flex-width(100%);}
	} 
}

/* For use ul list */
[class*=list-grid]{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin-right: -15px;
    margin-left: -15px;    
    list-style: none;
    position:relative; 
    

    li{
        padding-left:3*$pad;
        padding-right:3*$pad;
        padding-bottom:6*$pad;
    }
}

.list-grid{
    &-4, &-3, &-2{
        li{
            @include flex-width(100%);
        }        
    }
    &-4{
        li{
            &.two-column{
                @include flex-width(100%);

                [class*=-title]{
                    width: 49%;        
                }                
            }
        }        
    }    
    
    @media (min-width:700px){
        &-4{
            li{
                @include flex-width(25%);

                &.two-column{
                    @include flex-width(50%);

                    .img-link-card{
                        [class*=title-]{
                            width:49%;        
                        }
                    }
                }
            }        
        }
        &-3{
            li{
                @include flex-width(50%);
            }        
        }
        &-2{
            li{
                @include flex-width(50%);
            }
        }
    }
    
    @media (min-width:992px){  
        &-4{
            li{
                @include flex-width(25%);                
            }        
        }          
        &-3{
            li{
                @include flex-width(33.333333%);                
            }        
        }          
    }
}
