// Mobile Navigation
.mobile-nav{
    background-color: $b;
    color: $white;

    .mm-panel.mm-hasnavbar .mm-navbar{display: none;}
    .mm-panels>.mm-panel.mm-hasnavbar{padding-top: 0;}

    .mm-panels>.mm-panel{
        @include padding(0px);
    }
    
    ul{
        line-height: 1.3em;
        &.mm-listview{
            @include margin(0px);
        }

        &,li{
            width: 100%;
            color: $white;
        }

        li:after{display: none;}        
        
        li a{
            padding: 15px;
            display: block;
            font-size: 18px;
            color: $white;
            font-weight: $light;
            text-decoration: none;
        }

        &.childMenu{
            padding: 0px;
            margin-top:20px !important;
            background: transparent;
            border: 0px;
            @include box-shadow(0 14px 14px -10px rgba(#000, 0));

            li{
                &>a{    
                    &:hover{
                        color: var(--main-white-color) !important;
                    } 
                }
            }
        }

        .emphasis{background: none;}
    }

    ul.sub-menu li a{
        color: lighten($b, 25%);
    }

    .mm-menu .mm-btn:after {
        &.mm-menu .mm-btn:before{
            border-color: rgba(255,255,255,1);
        }
    }   
}




// Add New Feature Mobile Navigation css

.mm-navbar{
    .mm-btn{
        width: 50px;
        font-size: 18px; 

        &:last-child{
            text-align: left; right: 10px; top: 50%;
            @include vertical-align;
        }
    }

    &.mm-hasbtns {
        padding: 0 20px;
    }
}
.mm-navbars-top {
    border-bottom-width: 0px;
    padding: 10px 0;
    background-color: var(--main-secondary-color);
}
.mm-sronly {
    clip: inherit !important;
    -webkit-clip-path: inherit !important;
    overflow: inherit !important;  
    text-transform: uppercase; 
    letter-spacing: $font-space2;
    text-indent: -99999px;
}

.mm-menu.mm-theme-black{
    .mm-btn{
        &:after{
            border-color: rgba(255,255,255,1);
        }
        &:before{
            border-color: rgba(255,255,255,1);
        }
    }
}

.mm-menu{
    &.mm-theme-black{
        .mm-navbar{
            a{
                color: #fff;
            }
        }
    }
}
.mm-menu{
    &.mm-theme-black{
        .mm-navbar{
            a{
                color: #fff;
                font-size: 20px;
            }
            >*{
                color: #fff;
            }
        }        
    }
}

.mm-clear{
    &:after{
        width: 12px; height: 10px; right: 18px; top: -2px;        
    }
    &:before{
        width: 10px; height: 10px; right: 6px; top: -2px;
    }
}
.mm-close{
    &:after{
        width: 14px; height: 14px; right: 18px; top: -2px;       
    }
    &:before{
        width: 14px; height: 14px; right: 0px; top: -2px;
    }
}

.mob-logo{
    display: inline-block;
    float: left; padding: 20px 0;

    img{
        max-width: 80px;
    }
}

.mm-menu.mm-theme-black{
    .mm-listview{
        li{
            a{
                &:hover{
                    background: rgba(255,255,255,0) !important;
                }              
            }
        }
    }
}

.mm-panels{
    .mm-panel{
        .mm-navbar{margin-top: 0px;}
        .mm-listview{margin: 20px 0px;}
    }
}

.mm-panels>.mm-panel>.mm-navbar+.mm-listview{margin-top: 0px;}






