.social-media{
    &>ul{
        display: flex;
        align-items: center;

        li{
            margin:0 8px 0 0;            
            text-align: center;

            &>a{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 44px;
                height: 44px;
                @include border-radius(50%);
                @include font-size(20px, 16px);
                color: var(--main-white-color);
                background-color: var(--main-primary-color);

                &:hover{
                    background-color: var(--main-secondary-color); 
                }                
            } 
            
            &.remove-circle{
                &>a{                    
                    width: auto;
                    height: auto;
                    @include border-radius(50%);
                    @include font-size(30px, 16px);                    
                    color: var(--main-secondary-color);
                    background-color: $trans;
    
                    &:hover{
                        color: var(--main-primary-color); 
                    }
                    img{
                        max-height: 30px;
                    }
                }  
            }
        }
    }

    &.small{
        &>ul{ 
            li{
                margin:0 8px 0 0;    
                &>a{                    
                    width: 32px;
                    height: 32px;                    
                    @include font-size(16px, 16px);                    
                }
            }
        }
    }
}


