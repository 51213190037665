header.siteHeader{    
    >.container{position: relative;}
    
    // GLOBAL NAVIGATION
    nav.nav{
        width: auto;
        justify-content: flex-end;
        display: flex;        
        background-color: var(--main-primary-color);

        a{
            text-decoration: none; 
            display: inline-block;
        }        

        ul{
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center; 

            li{
                list-style: none;
                @include margin(0px);
                display: inline-block;
                position: relative;                
                height: auto; 
                vertical-align: middle;
                
                &.dropdown{                    
                    & > a{
                        padding-right:20px;
                        &:after {
                            @include fontAwesome;
                            content: "\f078"; 
                            margin-left:8px;                                
                            //right:0px;
                            position: relative;
                            top:20px;  
                            @include font-size(14px, 16px);
                            position: absolute;                            
                            @include transitions(all .5s 0s ease-in-out);                            
                        }
                    }

                    &:hover{                   
                        & > a{
                            &:after {                            
                                content: "\f077";                                                        
                            }
                        }
                    }
                }
                
                &>a{                    
                    display:block; 
                    position: relative;
                    color: var(--main-white-color);                    
                    @include font-size(15px, 16px);
                    padding:15px 0;
                    margin: 0 10px;                    
                    text-align: center;
                    font-weight: 300;               

                    &.active{
                        color: var(--main-white-color);
                        //text-decoration: underline;  
                    }
                }

                &:hover .childMenu{
                    visibility: visible;
                    opacity: 1;
                    @include translateY(0px);
                    display: block;
                }

                &:hover > a{                    
                    color: $lgrey;
                }

                li.active a, li a:hover {                    
                    color: $lgrey;
                }

                @include media(1100px, min){
                    &>a{                                            
                        @include font-size(16px, 16px);
                        margin: 0 10px;                                                 
                    }
                    &.dropdown{                    
                        & > a{                            
                            &:after {                                
                                top:21px;  
                                @include font-size(16px, 16px);                                                           
                            }
                        }
                    }
                }
                @include media(1270px, min){
                    &>a{                                            
                        @include font-size(18px, 16px);
                        margin: 0 15px;                                                 
                    }
                }
            }            

            @include media(1050px) {
                display: none;
            }
        }
    }    
}

// Scroll header stick
.childMenu{
    position: absolute; 
    left:0; top: 100%;     
    @include translateY(-20px);
    z-index:1001; 
    width: 250px !important;
    @include margin(0px);
    @include padding(10px);
    visibility: hidden;
    opacity: 0;     
    border-top: 0px;
    background: $lmgrey; 
    border: 1px solid $white;
    @include box-shadow(0 14px 14px -10px rgba(#000, 0.6));
    transition: transform 0.5s ease, opacity 0.5s ease;    

    li{
        width: 100%;
        border-bottom: 1px solid $white;
        border-right: 0px !important;        

        &>a{
            font-size: 15px !important;
            padding: 8px 10px !important;
            display: block !important;
            color: #000 !important;
            height: auto !important;
            margin: 0 !important;
            text-align: left !important; 

            .fa-angle-right{
                position: absolute; font-size: 22px;
                right: 10px; top: 10px;
            }

            &:hover{
                color: var(--main-hover-color) !important;
            } 
        }

        &:hover .sub-childMenu{
            visibility: visible;
            opacity: 1;  
            @include translateX(0px);

            li{
                .sub-child-sub{                    
                    visibility: hidden;
                    opacity: 0;  
                    @include translateX(-20px); 
                }

                &:hover .sub-child-sub{
                    visibility: visible;
                    opacity: 1;
                    @include translateX(0px);  
                }
            }
        }
    }    

    .sub-childMenu{
        left: 100%; top: 0px;
        position: absolute; 
        z-index:9; width: 100%;
        background: $lmgrey; 
        border: 1px solid $white;
        @include margin(0px);
        @include padding(0px);
        @include box-shadow(0 6px 30px -10px rgba(#000, 0.4) );
        visibility: hidden;
        opacity: 0;
        @include translateX(-20px);
        transition: transform 0.5s ease, opacity 0.5s ease;    
        
        li{
            line-height: 1.2;

            &>a{
                padding: 10px 0px !important;
            }
        }
        &.megaMenu{
            min-width: 665px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            top: -40px;           

            li{
                width: 200px;
                margin: 0 10px;                
            }
        }
    }

    .sub-child{
        @extend .sub-childMenu;
        display: block !important; 
        
        li{
            a{
                padding-left: 10px !important;
            }
        }
    }          
}



@media screen and (max-width: 1035px) {
    .childMenu{
        top: 0px;
        position: relative;
        visibility: visible;
        opacity: 1;
        width: 100% !important;
        

        li{
            border-bottom: inherit;

            a{
                font-size: 18px !important;               
                padding: 20px !important;                 
                color: $w !important; 

                .fa-angle-right{
                    display: none;
                }
                
            }
        }
    }
}

.mobile-nav {
    .mm-panels {
        .mm-panel{
            width: 100%;
        }
    }
}


// Mobile Menu specific styles
header.siteHeader {
    .toggle{        
        width: auto;
        padding: 20px;
        color: $b;
        float: right;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: $regular;
        font-size: 20px;

        position: absolute;
        right: 1%;
        top: 20px;
    }
    
    @include media(1050px, min){
        .toggle{
            display: none;
        }
    }
}

$bar-width: 30px;
$bar-height: 2px;
$bar-spacing: 8px;
$bar-color: $b;

.hamburger-menu {
    margin: auto;
    width: $bar-width;
    height: $bar-height + $bar-spacing*2;
    cursor: pointer;
    float: right;
    margin-left: 10px;
    margin-top: 3px;
    
    .bar,
    .bar:after,
    .bar:before {
        width: $bar-width;
        height: $bar-height;
    }

    .bar {
        position: relative;
        transform: translateY($bar-spacing);
        background: $bar-color;
        transition: all 0ms 300ms;
    
        &::before{
            content: "";
            position: absolute;
            left: 0;
            bottom: $bar-spacing;
            background: $bar-color;
            transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
        }

        &::after{
            content: "";
            position: absolute;
            left: 0;
            top: $bar-spacing;
            background: $bar-color;
            transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
        }
    }

    .mm-opening & .bar {
        background: rgba(255, 255, 255, 0); 
    }

    .mm-opening & .bar::after {
        top: 0;
        transform: rotate(45deg);
        transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
    }

    .mm-opening & .bar::before {
        bottom: 0;
        transform: rotate(-45deg);
        transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
    }
}


