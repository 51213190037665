.footer-gbl {    
    position: relative;
    padding: 30px 0 0;
    background-color: #fafafa;  

    
        
    .foot-left{
        text-align: center;
        font-weight: 300;
        padding: 0 15px 0 0;        
        margin: 0 0 30px;                 

        &>.logo{            
            margin-bottom: 20px;
            
            img{
                max-width: 100px;
            }
        }
        &>.country-name{
            color: $grey;
            margin-bottom: 20px; 
            font-size: 14px;
            line-height: 1.2;
        }
        &>.social-media{
            ul{
                justify-content: center;

                li{
                    margin:0 4px 0 0;            
                    text-align: center;
        
                    &>a{                            
                        width: 30px;
                        height: 30px;                            
                        @include font-size(14px, 16px);                                                       
                    }                        
                }
            }
        }
    }

    .foot-nav{
        font-weight: 300;
        padding: 0;        
        margin: 20px 0 0px;
        padding-top: 20px;
        border-top:1px solid $lgrey; 
                 
        
        &.bullet-list{
            ul{        
                li{
                   padding:0 0 0 20px;
                   margin:3px 0;                   
        
                    &:before {                                        
                        background-color: $dgrey;                
                    }
                    a{
                        color: $dgrey;
                        &:hover{
                            color: var(--main-hover-color);
                        }
                    }
                    &:hover{
                        &:before {                                        
                            background-color: var(--main-hover-color);                
                        }
                    }
                }
            }  
        }

        ul{
            display: flex; 
            flex-wrap: wrap;

            li{
                width: 50%;
            }
        }
    }

    .foot-disclaimer{
        font-weight: 300;
        padding: 20px 0 0;        
        margin: 0 0 10px; 
        font-size: 14px;  
        border-top:1px solid $lgrey;     

        p{
            color: $dgrey;
        }
    }

    .small-txt{
        padding: 20px 15px 10px;
        text-align: center;
        color: $dgrey;
        border-top:1px solid $lgrey;
        font-size: 14px;
        font-weight: 300;

        p{
            color: $dgrey;
            font-size: 14px;
            font-weight: 300;
        }
    }

    @include media(768px, min){       
        padding: 50px 0 0;

        .container{
            display: flex;
            flex-wrap: wrap;
        }

        .foot-left{            
            @include flex-width(60%);
            padding: 0px 25px;        
            margin: 0 0 50px; 
            border-right: 1px solid $lgrey;
            
            &>.logo{            
                margin-bottom: 20px;

                img{
                    max-width: 180px;
                }    
            }
            &>.country-name{
                font-size: 16px;
            }
            &>.social-media{
                ul{
                    li{
                        margin:0 8px 0 0;            
                        text-align: center;
            
                        &>a{                            
                            width: 40px;
                            height: 40px;                            
                            @include font-size(20px, 16px);                                                       
                        }                        
                    }
                }
            }
        }
        .foot-nav{            
            @include flex-width(40%); 
            padding: 0px 25px;        
            margin: 0 0 50px; 
            border-top: 0;            
        }
        .foot-disclaimer{
            font-size: 16px;
        }
        .small-txt{
            padding-bottom: 30px;            
        }
    }

    @include media(992px, min){
        .foot-left,
        .foot-nav,
        .foot-disclaimer{
            padding: 0px 25px;        
            margin: 0 0 50px;
            @include flex-width(33.33333333%);   
            border-top: 0px;     
        }
        .foot-nav{
            border-right: 1px solid $lgrey;            
        }
    }
}





    







