.mm-menu{
    background: $w;
}

.mm-listview{
  li  {

    color: $w; font-weight: $light; 
    font-size: 20px; text-align: left;
    letter-spacing: $font-space1;    

    a{
        padding:15px;
        border-bottom: 1px solid #1f5878;

    }

    &.active{
        a{
            color: $mobnav-active;
        }
    };
  }
}
.mm-menu .mm-listview>li.mm-selected>a:not(.mm-next), 
.mm-menu .mm-listview>li.mm-selected>span {
    background: transparent;
    color: $gbltxt-hover;
}