.what-is-toastmaster{
    .blocks{
        position: relative;
        height: 100%;
        padding:50px;
        @include box-shadow(0 0 15px 0 rgba(#000, 0.2));
        background-color: var(--main-white-color);
        flex-direction: column;
        display: flex;

        &:after{
            content: '';
            border: 2px solid var(--main-primary-color);            
            position: absolute;
            left: 20px;
            right: 20px;
            top: 20px;
            bottom:20px;
            z-index: 0;
        }

        h3, p, .cta-red, .cta-white{
            z-index: 1;
            position: relative;            
        }
        
        p{
            font-weight: 300;
            margin-bottom: 30px;
        }

        .cta-red, .cta-white{
            margin-top:auto;
            align-self: flex-start;
        }

        &.alt{
            background-color: var(--main-primary-color);
            color: var(--main-white-color);

            h3, p{
                color: var(--main-white-color);
            }            

            &:after{
                border: 2px solid var(--main-white-color);
            }
        }
    }
}