%dfp{
	display: block;
	position: relative;
	float: left;
	height: auto;
}

%dpa{
	display: block;
	position: absolute; 
	top: 0;
	left: 0;
}

%dpCover{
	@extend %dpa;  
	width: 100%;
	height: 100%;
}

%listless,
.listless{
	width:100%; 
	&,
	>li{
		@extend %dfp;
		list-style: none;
		margin: 0;
		padding: 0;
	}
}

%noLink{
	text-decoration: none;
	color: inherit;
}

%bgc{
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}