.news-events{
    //display: flex;
    //flex-direction: column;
    //@include flex-width(50%);
    //float: left;
    width: 100%;
    padding:0; 
    margin-bottom: 30px;   

    header{
        h2{
            margin-bottom: 10px;
        }
    }

    .news-list{
        ul{
            li{
                display: flex;
                padding-right:110px;

                .date{
                    background-color: $lgrey;                    
                    font-size: 13px;
                    font-weight: 300;
                    padding: 2px 12px;
                    border-radius: 12px;
                    white-space: nowrap;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                }
            }
        }
    }

    @include media(992px, min){
        float: left;
        width: 50%;
        padding:0 40px 0 0; 
        margin-bottom: 0;
        border-right:2px solid $lgrey;
    }
}