// Button Stylings for Cookies Noti bar

[class*=cta-]{    
	display: inline-block;
    text-align: center;
    text-decoration: none; 
    position: relative; 
    border: 0px; 
    outline: none;
    cursor: pointer;
    font-weight: $regular;  
    text-transform: uppercase;
    @include border-radius(10px);
    @include padding-all(10px, 20px, 10px, 20px);            
    
    &.mt-10{
        margin-top:2*$mar;
    }
    &.mt-20{
        margin-top:4*$mar;
    }
    &.mt-30{
        margin-top:6*$mar;
    }
    &.mt-40{
        margin-top:8*$mar;
    }
    &.mt-50{
        margin-top:10*$mar;
    }
}

.cta-red{   
    background: var(--main-secondary-color);
    white-space: nowrap;  
    color:$w;
    @include box-shadow(0 0 10px 0 rgba(#000, 0.2));

    &:hover{
        background: var( --main-primary-color);
        color:$w;       
    }
}
.cta-white{   
    background: var(--main-white-color);
    white-space: nowrap;  
    color: var( --main-primary-color);
    @include box-shadow(0 0 10px 0 rgba(#000, 0.2));

    &:hover{
        background: var(--main-secondary-color);
        color: var( --main-white-color);;       
    }
}


 

