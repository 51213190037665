@use "sass:math";

@mixin selfClearing{
	&::after{
		content: "";
		display: table;
		clear: both;
	}
}
                              
@mixin fontAwesome{
	font-family: 'Font Awesome 5 Pro';
	-moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
	font-weight: $regular;
}
@mixin flex-width($width) {
    -ms-flex: 0 0 $width;
    flex: 0 0 $width;
    max-width: $width;
}
@mixin font-size($size, $base) {
    font-size: $size; // fallback for old browsers
    font-size: ($size / $base) * 1rem;
}
@mixin textShadow{
	text-shadow:0px 2px 1px rgba($black, 0.6);
}

%dfp{
    display:block;
    float:left;
    position:relative;
}
%dpa{
    display:block;
    position:absolute;
    top:0;
    left:0;
}

%dpCover{
    @extend %dpa;
    width:100%;
    height:100%;
}

@mixin ie8{
    html.generatedcontent.no-rgba & {
        @content;
    }
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}
@mixin border-radius-all($tlradius, $trradius, $brradius, $blradius) {
    -webkit-border-top-left-radius: $tlradius;
    -moz-border-top-left-radius: $tlradius;    border-top-left-radius: $tlradius;
    -webkit-border-top-right-radius: $trradius;
    -moz-border-top-right-radius: $trradius;
    border-top-right-radius: $trradius;
    -webkit-border-bottom-right-radius: $brradius;
    -moz-border-bottom-right-radius: $brradius;
    border-bottom-right-radius: $brradius;
    -webkit-border-bottom-left-radius: $blradius;
    -moz-border-bottom-left-radius: $blradius;
    border-bottom-left-radius: $blradius;
}
@mixin box-shadow($shadows...) {
    -moz-box-shadow: $shadows;
    -webkit-box-shadow: $shadows;
    box-shadow: $shadows;
}
@mixin blur($blur){
    filter: blur($blur);
     -webkit-filter: blur($blur);
}
@mixin global-shadow {
    -moz-box-shadow: $global-shadow-style;
    -webkit-box-shadow: $global-shadow-style;
    box-shadow: $global-shadow-style;
}
@mixin box-sizing {
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    box-sizing: border-box; 
}
@mixin no-box-sizing {
    -webkit-box-sizing: content-box; 
    -moz-box-sizing: content-box; 
    box-sizing: content-box; 
}
@mixin bgCover{
    background-repeat: no-repeat;
    background-position:center center;
    -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    // @include ie8{
        img.bg{
            width:100% !important;
            max-width:1000% !important;
            height:auto !important;
        }
    // }
}
@mixin circle($diameter) {
    $circ-radius: $diameter*0.5;
    height: $diameter;
    width: $diameter;
    -moz-border-radius: $circ-radius;
    border-radius: $circ-radius;
}
@mixin rotate($angle) {
    -webkit-transform: rotate($angle);  
    -moz-transform: rotate($angle); 
    -ms-transform: rotate($angle);  
    -o-transform: rotate($angle);  
    transform: rotate($angle);  
}
@mixin rotateY($angle) {
    -webkit-transform: rotateY($angle);  
    -moz-transform: rotateY($angle); 
    -ms-transform: rotateY($angle);  
    -o-transform: rotateY($angle);  
    transform: rotateY($angle);  
}
@mixin text-columns($colnumber,$gutter) {
    -webkit-column-count: $colnumber;  
    -webkit-column-gap: $gutter; 
    -moz-column-count: $colnumber;     
    -moz-column-gap: $gutter; 
    column-count: $colnumber;          
    column-gap: $gutter; 
}
@mixin transform-translate($value...) {
    -webkit-transform: translate3d($value); 
    -moz-transform: translate3d($value); 
    -ms-transform: translate3d($value); 
    -o-transform: translate3d($value);  
    transform: translate3d($value); 
}
@mixin translateY($value...) {
    -webkit-transform: translateY($value); 
    -moz-transform: translateY($value); 
    -ms-transform: translateY($value); 
    -o-transform: translateY($value);  
    transform: translateY($value); 
}
@mixin translateX($value...) {
    -webkit-transform: translateX($value); 
    -moz-transform: translateX($value); 
    -ms-transform: translateX($value); 
    -o-transform: translateX($value);  
    transform: translateX($value); 
}
@mixin transform-scale($scale) {
    -webkit-transform: scale($scale); 
    -moz-transform: scale($scale); 
    -ms-transform: scale($scale); 
    -o-transform: scale($scale);  
    transform: scale($scale); 
}

@mixin scale($scale){
    @include transform-scale($scale);
}

@mixin skew($deg){
    transform: skewX($deg); 
    -ms-transform: skewX($deg); /* IE 9 */
    -webkit-transform: skewX($deg); /* Safari and Chrome */
}

@mixin transform($styles...) {
    -webkit-transform:$styles; 
    -moz-transform:$styles; 
    -ms-transform:$styles; 
    -o-transform:$styles;  
    transform:$styles; 
}
@mixin transition($what,$time) {
    transition: $what $time ease-in-out;
    -ms-transition: $what $time ease-in-out;
    -o-transition: $what $time ease-in-out;
    -webkit-transition: $what $time ease-in-out;
    -moz-transition: $what $time ease-in-out;
}
@mixin transition-all($time) {
    transition: all $time ease-in-out;
    -ms-transition: all $time ease-in-out;
    -o-transition: all $time ease-in-out;
    -webkit-transition: all $time ease-in-out;
    -moz-transition: all $time ease-in-out;
}
@mixin transitions($transitions...) {
    transition: $transitions;
    -ms-transition: $transitions;
    -o-transition: $transitions;
    -webkit-transition: $transitions;
    -moz-transition: $transitions;
}
@mixin animation($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}
@mixin gradient($fromColor, $toColor) {
  // background-color: $toColor;
  background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor)); 
  background-image: -webkit-linear-gradient(top, $fromColor, $toColor); 
  background-image:    -moz-linear-gradient(top, $fromColor, $toColor); 
  background-image:     -ms-linear-gradient(top, $fromColor, $toColor); 
  background-image:      -o-linear-gradient(top, $fromColor, $toColor);
  background-image:         linear-gradient(top, $fromColor, $toColor);
  //filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}
@mixin gradientDown($fromColor, $toColor) {
  background-color: $fromColor;
  background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor)); 
  background-image: -webkit-linear-gradient(top, $fromColor, $toColor); 
  background-image:    -moz-linear-gradient(top, $fromColor, $toColor); 
  background-image:     -ms-linear-gradient(top, $fromColor, $toColor); 
  background-image:      -o-linear-gradient(top, $fromColor, $toColor);
  background-image:         linear-gradient(top, $fromColor, $toColor);
  //filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}
@mixin gradHor($color1, $color2) {
  background: $color1; /* Old browsers */
  background: -moz-linear-gradient(left,  $color1 0%, $color2 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,$color1), color-stop(100%,$color2)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left,  $color1 0%,$color2 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left,  $color1 0%,$color2 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left,  $color1 0%,$color2 100%); /* IE10+ */
  background: linear-gradient(to right,  $color1 0%,$color2 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=1 ); /* IE6-9 */
}
@mixin gradient-full($dir, $stops...){  // Full Linear Gradient Customisation
  $list:$stops;
  $fromColor:nth(($list), 1);
  $toColor:nth($list, length($list));
  background-color: $toColor;
  background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor)); 
  background-image: -webkit-linear-gradient($dir, $stops...); 
  background-image:    -moz-linear-gradient($dir, $stops...); 
  background-image:     -ms-linear-gradient($dir, $stops...); 
  background-image:      -o-linear-gradient($dir, $stops...);
  background-image:         linear-gradient($dir, $stops...);
  //filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}
@mixin basicGrad($color) {
    $fromColor:lighten($color, 10);
    $toColor:darken($color, 10);
  background-color: $color;
  background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor)); 
  background-image: -webkit-linear-gradient(top, $fromColor, $toColor); 
  background-image:    -moz-linear-gradient(top, $fromColor, $toColor); 
  background-image:     -ms-linear-gradient(top, $fromColor, $toColor); 
  background-image:      -o-linear-gradient(top, $fromColor, $toColor);
  background-image:         linear-gradient(top, $fromColor, $toColor);
  //filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}
@mixin revGrad($color) {
    $fromColor:darken($color, 10);
    $toColor:lighten($color, 10);
  background-color: $color;
  background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor)); 
  background-image: -webkit-linear-gradient(top, $fromColor, $toColor); 
  background-image:    -moz-linear-gradient(top, $fromColor, $toColor); 
  background-image:     -ms-linear-gradient(top, $fromColor, $toColor); 
  background-image:      -o-linear-gradient(top, $fromColor, $toColor);
  background-image:         linear-gradient(top, $fromColor, $toColor);
  //filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}
@mixin diagGrad($angle,$stop1,$stop2){
    background: $stop2; /* Old browsers */
    background: -moz-linear-gradient($angle,  $stop1 0%, $stop2 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,$stop1), color-stop(100%,$stop2)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient($angle,  $stop1 0%,$stop2 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient($angle,  $stop1 0%,$stop2 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient($angle,  $stop1 0%,$stop2 100%); /* IE10+ */
    background: linear-gradient(90-$angle,  $stop1 0%,$stop2 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$stop1', endColorstr='$stop2',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}
@mixin triangle($dim,$col){
    content:"";
    display:block;
    border-right:$dim solid transparent;
    border-left:$dim solid transparent;
    border-top:$dim solid $col;
}

@mixin vertical-align {
  position: absolute;
  top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}


@mixin no-vertical-align {
  position: relative;
  top: 0%;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

@mixin horizontal-align {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
@mixin no-horizontal-align {
    position: absolute;
    top: 0%;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

@mixin middle {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
    -ms-transform:translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin no-middle{
    top: 0%;
    left: 0%;
    -webkit-transform: translate(-0%, -0%);
    -ms-transform:translate(-0%, -0%);
    transform: translate(-0%, -0%);
}

@mixin highlight($what){
    body,#window{
        background-color:black;
        section,header,footer{
            opacity:0.4;
        }
        #{$what}{
            opacity:1 !important;
            z-index:10000 !important;
        }
    }
}

@mixin columnBreakNone(){
    -webkit-column-break-inside:avoid;
    -moz-column-break-inside:avoid;
    -o-column-break-inside:avoid;
    -ms-column-break-inside:avoid;
    column-break-inside:avoid;
}

/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
/// @param {Color} $foreground-color - Scrollbar's color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  ::-webkit-scrollbar {
      width:  $size;
      height: $size;
  }

  ::-webkit-scrollbar-thumb {
      background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
      background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin backfaceVisible{
    -webkit-backface-visibility: visible; /* Chrome, Safari, Opera */
    backface-visibility: visible;
}

@mixin backfaceHidden{
    -webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
    backface-visibility: hidden;
}

@mixin equalWidths($num){
    @for $i from 1 through $num {
        &:first-child:nth-last-child(#{$i}),
        &:first-child:nth-last-child(#{$i}) ~ * {
            width:math.div(100, $i)*1%;
        }
    }
}

@mixin hashBG($val,$col:darken($bg,5),$bg:$trans,$fallback:$lgrey){
    background: repeating-linear-gradient(
      -45deg,
      $bg,
      $bg 8px*$val,
      $col 8px*$val,
      $bg 9px*$val,
      $bg 10px*$val
    );
    background-size:12px*$val 12px*$val;
    @include ie8{
        background:$fallback;
    }
}

@mixin media($break,$dir:max){
    @media screen and (#{$dir}-width:$break){
        @content
    }
}

@mixin edgeMargins(){
    >*:first-child:not([class]){
        margin-top:0;
    }
    >*:last-child:not([class]){
        margin-bottom:0;
    }
}

@mixin selfClearing{
    &::after{
        content: "";
        display: table;
        clear: both;
    }
}

@mixin wordBreak(){
    -ms-word-break: break-all;
    word-break: break-all;

    // Non standard for webkit
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

@mixin placeholder(){
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        @content;
    }
    :-ms-input-placeholder { /* IE 10+ */
        @content;
    }
    :-moz-placeholder { /* Firefox 18- */
        @content;
    }
}

// Padding shorthand version
@mixin padding($padding) {
	padding: $padding;
}

// Padding longhand version
@mixin padding-all($top, $right, $bottom, $left) {
	padding-top: $top;
	padding-right: $right;
	padding-bottom: $bottom;
	padding-left: $left;
}
@mixin padding($values...) {    
    @each $var in $values {
        padding: #{$var};
    }
}

// Margin shorthand version
@mixin margin($margin) {
	margin: $margin;
}

// Margin longhand version
@mixin margin-all($top, $right, $bottom, $left) {
	margin-top: $top;
	margin-right: $right;
	margin-bottom: $bottom;
	margin-left: $left;
}

%listless{
    &,
    &>li{
        margin:0;
        padding:0;
        list-style:none;
        display:block;
        float:left;
    }
}

@mixin backface-hidden{
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}

@mixin clipPath($val...){
  -webkit-clip-path: polygon($val);
  clip-path: polygon($val);
}

@mixin flex-width($width) {
    -ms-flex: 0 0 $width;
    flex: 0 0 $width;
    max-width: $width;
}

@mixin font-size($size, $base) {
    font-size: $size; // fallback for old browsers
    font-size: math.div($size, $base) * 1rem;
}