/*
Radiator Digital
http://www.radiatordigital.com
*/

//===================================================================//
// =====================  BASE VARIABLES   ======================== //
//=================================================================//

// DIMENSIONS & GRID
// --------------------------------
@use "sass:math";

// DIMENSIONS & GRID
// --------------------------------
$cw-xxxl: 1920px;
$cw-xxl: 1280px;
$cw-xl: 1100px;
$cw-m: 880px;
$cw-sm: 680px;

// Variables Declaration
// --------------------------------

:root {    
    --main-hover-color: #772432;
    --main-primary-color: #004165;    
    --main-secondary-color: #772432;
    --main-text-color: #222222; 
    --main-white-color: #ffffff;
    --main-black-color: #000000; 

    --main-border-color: #004165;
    
    --main-grey-color: #4c4b4b;
}


// FONT-WEIGHT - $MAIN
// --------------------------------
$slight: 100;
$light: 300;
$regular: 400;
$med: 500;
$sum-med: 600;
$bold: 700;
$strong: 700;
$weightBlack: 900;

$kanit: 'Kanit', sans-serif;
$Montserrat: 'Montserrat', sans-serif;

// FONT SPACING
// --------------------------------
$font-space1: 1px;
$font-space2: 2px;
$font-space3: 3px;
$font-space-normal: normal;

$gbltxt-hover: #fbb10e;
$primary-color: #ff0f37;
$secondary-color: #26A8DE;
$text-color: #383838;
$nav-hover-color: #ff0f37;
$nav-active-color: #ff0f37;
$mobnav-active: #980921;
$footer-color: #a2a2a2;


// BASE COLOR SET
// --------------------------------

$trans: transparent;
$white: #fff;
$black: #000;
$w: #fff;
$b: #000;
$ddgrey: #444;
$dgrey: #666;
$grey: #8a8a8a;
$lmgrey: #e5e5e5;
$lgrey: #cfcfcf;
$slgrey: #fafafa;
$red: #C81E26;
$dgreen: #00bf8f;

$blu: #278ac9;
$ylo: #ffa200;



// FX
// --------------------------------
$shadow: 0px 0px 5px 0px #333;  // Global Box Shadow Style (if applicable)
$headShad: 0px 0px 10px $black;
