.header{    
    position: relative;    
    z-index:9;        
    opacity: 1;   
    
    .head-grid{
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;        
    }

    .logo,
    .district{
        padding: 10px 15px;        
        @include flex-width(100%);
        text-align: center;

        span{
            display: block;
            text-transform: uppercase;
            font-weight: 500;
            margin-top:10px;
            font-size: 16px;
            word-break: break-all;
            line-height: 1.2;
            color:var(--main-secondary-color);
        }
    }

   .logo{        
        img{
            max-width: 100px;
        }   
        a{
            display: block;
        }        
    }

    .district{
        display: flex; 
        align-items: center;
        justify-content: center;
        flex-direction: column; 

        h1{
            text-transform: uppercase;
            @include font-size(36px, 16px); 
        }
        h5{
            font-weight: 300;
            margin-top: 10px;
        }        
    }

    .social-search{        
        display: flex; 
        align-items: center;
        justify-content: center;
        flex-direction: column; 
        padding: 10px 25px;        
        @include flex-width(100%);
        
        .search{
            width: 100%;
            position: relative;
            margin: 20px 0;
            form{
                .fa-search{
                    position: absolute;
                    left: 10px;
                    top:15px;
                    z-index: 2;
                }
                .form-control{
                    padding-left: 35px;
                    padding-right: 35px;
                }
                .cta-remove{
                    position: absolute;
                    right: 2px;
                    top: 1px;
                    padding: 0px;
                    height: 44px;
                    width: 30px;
                    background-color: $trans;
                }
            }
            
        }
    }

    @include media(768px, min){
        .logo,
        .district{
            margin: 10px 0;
            @include flex-width(45%);                      
        }

        .logo{
            border-right: 2px solid var(--main-border-color);
            img{
                max-width: 140px;
            }                   
        }
    }

    @include media(1050px, min){
        .logo,
        .district{
            padding: 10px 25px;        
            margin: 20px 0;
            @include flex-width(33.333%);
            span{
                font-weight: 700;
                font-size: 18px;  
                margin-top:20px;              
            }
        }

        .logo{
            img{
                max-width: 180px;
            }                   
        }

        .district{
            border-right: 2px solid var(--main-border-color);            
    
            h1{                
                letter-spacing: 2px;
                @include font-size(46px, 16px); 
            }                  
        }

        .social-search{
            margin: 20px 0;
            @include flex-width(33.333%);

            .social-media{
                margin: 20px 0;
            }
        }
    }
}





    







