
.owl-theme {
    .owl-nav{
        &.disabled+.owl-dots {
            margin-top: 0px;
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 50px;

            .owl-dot {
                span {
                    width: 36px;
                    height: 10px;
                    margin: 0 8px;
                    background: #ffffff;
                    border-radius: 4px;
                    @include box-shadow(0 0 15px 0 rgba(#000, 0.2));
                }
                &.active{
                    span {
                        width: 44px;
                        background: var(--main-hover-color);
                    }
                }
            }
        }
    }
}

.owl-carousel {
    .item-video {
        max-height: 350px;
        height: 100vh;
    }

    @include media(768px, min){
        .item-video {
            max-height: 550px;            
        }
    }
}














