// PAGE FORM
.checkbox-list input[type=file]:focus, 
.checkbox-list input[type=checkbox]:focus, 
.checkbox-list input[type=radio]:focus {outline: none; outline-offset: 0;}

.field {
    margin-bottom: 4*$mar;
    @include selfClearing;

    label{
        display: block;
        width: 100%;
        margin-bottom:2*$mar;
        @include font-size(16px, 16px);
        color: var(--main-text-color);
    }

    .error{        
        border-color: $red !important;        
        &+.error{        
            color: $red;
            font-weight: $regular !important;
            border-color: $trans !important;
            font-size: 14px !important; 
            margin:$mar 0 0;
        }
    }
}

.form-control,
.form-select{
    display: block;
    width: 100%;    
    padding: 2*$pad;
    line-height: 1.5;
    color: var(--main-text-color);
    background-color: var(--main-white-color);
    border: 1px solid var(--main-secondary-color);
    border-radius: 4px;
    outline: none;

    &:focus {        
        outline: none;
    }    
}

/* Checkbox Css Style */
.form-check{
    cursor: pointer;  
    position: relative; 
    padding: 0 0 0 30px; 
    margin-bottom:0px !important;    
    font-weight:$light;
    width:100%;
    display:block;
    @include font-size(16px, 16px);

    .error{
        border-color: $red !important;        
        &+label{        
            display:none !important;
        }
    }

    .checkbox{
        -webkit-appearance: none; -moz-appearance: none; 
        -ms-appearance: none; -o-appearance: none; 
        appearance: none; position: absolute; 
        top: 4px; right: 0; bottom: 0; left: 0;
        z-index: 9;         
        height: 16px; width: 16px; 
        border: 1px solid #ebebeb; 
        transition: all 0.15s ease-out 0s;    
        display: inline-block; 
        outline: none; 
        cursor: pointer; 
        background-color: var(--main-white-color);
        border: 1px solid var(--main-secondary-color);

        &:checked{
            background-color: var(--main-white-color);
            border: 1px solid var(--main-secondary-color);

            &:before {
                content: '✔';
                line-height: 16px; 
                position: absolute; 
                left: 2px;
                top: -4px;
                display: inline-block; 
                font-size: 22px; 
                font-weight: $bold;
                text-align: center;                
                color: var(--main-primary-color);
            }
        }        
    }
    a{text-decoration: underline;}

    &.inline{
        display:inline-block;
        width:auto; 
        margin-right:4*$mar;
        font-weight:$regular;
        @include font-size(16px, 16px);
    } 
    
    &.larg{
        font-size: 20px !important;
        font-weight:$med !important;

        .checkbox{            
            top: 5px;            
            height: 20px; width: 20px;    
            &:checked{               
    
                &:before {                    
                    height:20px; 
                    width: 20px; 
                    line-height: 20px;                     
                    font-size: 14px;                     
                }
            }
        }
    }
}

.form-radio {
    @extend .form-check;
    padding: $pad 0 $pad 7*$pad;   
    
    .radiobox{
        -webkit-appearance: none; -moz-appearance: none; 
        -ms-appearance: none; -o-appearance: none; 
        appearance: none; position: absolute; 
        top: 4px; right: 0; bottom: 0; left: 0; z-index: 9; 
        @include border-radius(50%);
        height: 22px; width: 22px; 
        border: 2px solid #ebebeb; 
        transition: all 0.15s ease-out 0s;    
        display: inline-block; 
        outline: none; 
        cursor: pointer; 
        background-color: var(--main-white-color);
        border: 1px solid var(--main-secondary-color);
        @include box-shadow(0px 3px 5px 0px rgba(#000000, 0.4)); 

        &:checked{
            &:before {
                content: ''; 
                height:10px; 
                width: 10px; 
                display: inline-block;
                @include border-radius(50%);                
                @include middle; 
                background: var(--main-primary-color);
            }
        }
    }
}
/* End Css */

textarea.form-control {
    height: auto; 
}


::-webkit-input-placeholder { /* Edge */
    color: var(--main-text-color) !important;
}  
:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: var(--main-text-color) !important;
}  
::placeholder {
color: var(--main-text-color) !important;
}

.success-msg-txt,
.error-msg-txt{
    position: relative;
    padding-left:25px;    
    font-weight: $light;
    display: inline-block;
    @include font-size(18px, 16px);    
    &::before{
        position: absolute;                                    
        top:5px;
        left: 0px;               
        @include font-size(16px, 16px);              
    }
}

.error-msg-txt{    
    @extend .fa-exclamation-triangle;
    color: $red;
    &::before{
        @extend .far;                   
    }
}
.success-msg-txt{    
    @extend .fa-check;
    color: $dgreen;
    &::before{
        @extend .fas; 
        @include font-size(18px, 16px);                  
    }
}

