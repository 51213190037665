// Quick Facts Section Style
.count-feature{
    text-align: center;    
    //@include gradient(#ffe377, #fdd023);

    background-color: var(--main-primary-color);
    @include gradient(#004165, #004c77);

    .countbox{      
        padding: 50px 0;   
        color: var(--main-white-color);
        border-left: 1px solid #4d7e98;
        border-right: 1px solid #000000;        

        &:first-child{
            border-left: 0;
        }
        &:last-child{
            border-right: 0;
        }

        .icon{
            display: block;
            text-align: center;
            position: relative;            
    
            .fas{ 
                @include font-size(50, 16);
                width: 100px;
                height: 100px;                
                @include border-radius(100%);
                border: 2px solid;
                color: var(--main-white-color);
                opacity: 0.3;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;
            }
        }

        p{
            //color: $secondary-color;
            @include font-size(20, 16);
            font-weight: $light;
            margin-top: -10px;
            margin-bottom: 0px;
            color: var(--main-white-color);
        }

        .fact-symbol{                            
            @include font-size(22, 16);
            position: relative; 
            top: -16px;
            color: var(--main-white-color);

            &.plus{
                font-size: 30px;
                top: -10px;
            }
        }
        
        .counter-value{
            text-transform: inherit; 
            @include font-size(40, 16);
            color: var(--main-white-color);
        }
    }

    @include media(640px){
        @include padding-all(20px, null, 20px, null);
        
        .countbox{
            @include padding-all(10px, null, 10px, null);

            .icon{
                .fa{
                    @include font-size(50, 16);
                    width: 100px;
                    height: 100px;
                    line-height: 90px;                    
                }
            }
        }
    }
}