*, *::before, *::after{
    @include box-sizing;
}

::selection {
    background: #efefef;
}

html.mm-blocking{
    overflow: visible;
}
body,
html,
header,
footer,
section,
article,
main,
nav{
    width: 100%;
    display: block; 
    margin: 0;
    padding: 0;
}
input{max-width: 100%;}
blockquote{margin: 0;}


img, svg{
    height: auto;
    max-width: 100%;
}
a:focus {
    outline: none;    
}

body {    
    @include font-size(16px, 16px); 
    font-family: $Montserrat;
    font-weight: $regular;
    color: var(--main-text-color);   
    padding:0px;
    margin:0px;
    -webkit-font-smoothing: antialiased;  
    -webkit-text-size-adjust: 100%; 
    overflow-x: hidden;
    scroll-behavior:smooth;    
    
    @media (min-width:768px){
        @include font-size(16px, 16px); 
    }  
}
a{
    text-decoration:none;     
    color: var(--main-text-color);

    &:hover{
        text-decoration:none;
        color: var(--main-hover-color);
        //@include transitions(all .3s 0s ease-in-out);  
    }
}
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,0.1);
}

#window{
    width: 100%;
    min-height: 100%;
    @include transition(transform, .4s); 
    @include selfClearing;    
    transition: transform .5s cubic-bezier(.075,.82,.165,1);    
}
.container{
    max-width:1460px; 
    width:100%;  
    padding-left:15px;
    padding-right:15px;   
    margin: 0 auto;    
    position: relative;
    @include selfClearing;     
}

.body-wrap{
    min-height:500px;    
    width:100%;
    margin: 0 auto;    
    @include selfClearing;    
}
.wrapper{
    min-height:400px;   
    width:100%;  
    padding: 0 10*$pad;  
    @include selfClearing;

    @media(min-width:992px){
        padding: 0 19*$pad; 
    }    
}

.none{display: none;}
.text-left{text-align:left;}
.text-right{text-align:right;}
.text-center{text-align:center;}

.shadow{@include box-shadow(0 0px 10px 0px rgba(0, 0, 0, 0.2));}

.gbl{
    margin-top: 60px;
    margin-bottom: 60px;

    &-30{
        margin-top: 30px;
        margin-bottom: 30px;
    }    

    &-top{
        &-30{margin-top: 30px;}
        &-40{margin-top: 40px;}
        &-50{margin-top: 40px;}
        &-60{margin-top: 60px;}        
    }
    &-bottom{
        &-30{margin-bottom: 30px;}
        &-40{margin-bottom: 40px;}
        &-50{margin-bottom: 40px;}
        &-60{margin-bottom: 60px;}        
    }    

    @include media(992px){        
        margin-top: 35px;
        margin-bottom: 35px;        
        
        &-top{
            &-40{margin-top: 30px;}
            &-50{margin-top: 30px;}
            &-60{margin-top: 30px;}
        }
        &-bottom{            
            &-40{margin-bottom: 30px;}
            &-50{margin-bottom: 30px;}
            &-60{margin-bottom: 30px;}
        }
    }
}

// Heading sizes 
h1, h2, h3, h4, h5, h6 { 
    font-family: $Montserrat; 
    color: var(--main-text-color);
    font-weight: 500;
    padding: 0;
    margin: 0;
    position: relative;
    line-height: normal;
    line-height: 1;
}

h1{
    @include font-size(46px, 16px); 
    font-weight: 700;   
}
h2{
    @include font-size(40px, 16px);
    color: var(--main-primary-color);
    margin-bottom: 20px;   
}
h3{
    @include font-size(24px, 16px); 
    color: var(--main-secondary-color); 
    margin-bottom: 15px;  
}
h4{
    @include font-size(24px, 16px);   
    color: var(--main-secondary-color); 
    margin-bottom: 10px; 
}
h5{
    @include font-size(20px, 16px);    
}
h6{
    @include font-size(18px, 16px);    
}

.heading{
    h2{        
        padding-bottom: 15px;
        margin-bottom: 30px;
        @include font-size(30px, 16px);
    
        &:after{
            content: "";
            width: 100px;
            height: 4px;
            background-color: var(--main-primary-color);
            position: absolute;
            bottom: 0px;
            left: 0;            
        }      
    }
    &.text-center{
        h2{        
            &:after{                
                @include horizontal-align;
            }      
        }
    }

    @include media(768px, min){
        h2{  
            @include font-size(40px, 16px);      
            padding-bottom: 15px;
            margin-bottom: 40px; 
        }
    }
}

ul{
    padding: 0;
    margin: 0;
    list-style: none;

    li{
        padding: 0;
        margin: 0;
    }
}


@media (min-width:768px){ 
    h3{
        @include font-size(30px, 16px);  
        margin-bottom: 20px;         
    }
}


img,
video{    
    &.cover{
        -o-object-fit: cover;
        object-fit: cover;
        @include middle;
        min-width:100%;
        min-height:100%;
    }
}
figure {
    display: block;
    margin: 0 auto;
    @include selfClearing;

    img{
        width: 100%;
        display: block;
        @include transitions(all .5s 0s ease-in-out);
    }
}

ul.unList,
ol.unList{
    list-style-type:none;
    margin:0;
    padding:0;
    display:flex;

    > li{
        display:flex;
    }

    > li > a{
        display:flex;
        width:100%;
        text-decoration:none;
    }
}

@media (min-width:768px) {
    .modal-small {max-width:450px; width:100%;}
    .modal-sm {max-width:650px; width:100%;}
    .modal-md {max-width:850px; width:100%;}
    .modal-lg {max-width:1050px; width:100%;}
}





