.flip-card {
    perspective: 1000px;

    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        @include transitions(0.6s);
        transform-style: preserve-3d;        

        .flip-card-front {
            position: relative;
            background-color: var(--main-primary-color);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;  
            border: 15px solid var(--main-white-color);
            @include box-shadow(0 0 15px 0 rgba(#000, 0.2));         

            img{
                -o-object-fit: cover;
                object-fit: cover;
                width: 100%;
                height: 100%;
                max-height: 370px;
            }            
        }
          
        .flip-card-back {
            position: absolute;
            left: 0;
            top:0;
            width: 100%;
            height: 100%;
            padding: 25px;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            background-color: $lgrey;            
            color: var(--main-white-color);
            @include rotateY(180deg);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            h3{
                color: var(--main-primary-color);
                margin-bottom: 10px;
                position: relative;
                z-index: 1;
            }
            h4{
                color: var(--main-secondary-color); 
                font-size: 22px;   
                margin-bottom: 20px;
                position: relative;
                font-weight: 300;
                z-index: 1;           
            }
            .social-icon{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 50px;
                @include border-radius(50%);
                @include font-size(24px, 16px);
                color: var(--main-white-color);
                background-color: var(--main-primary-color);
                position: relative;
                z-index: 1;

                @include translateY(10px);
                transition: transform 0.5s ease, opacity 0.5s ease;    

                &:hover{
                    background-color: var(--main-secondary-color);
                    @include translateY(0px); 
                }                
            }

            &:after{
                content: '';
                border: 2px solid var(--main-primary-color);            
                position: absolute;
                left: 15px;
                right: 15px;
                top: 15px;
                bottom:15px;
                z-index: 0;
            }
        }
    }

    &:hover{
        .flip-card-inner {
            @include rotateY(180deg);
          }
    }
}


.our-team{
    @include media(1200px, max){
        .list-grid{
            &-4{
                li{
                    @include flex-width(100%);
                }        
            }  
            @media (min-width:580px){
                &-4{
                    li{
                        @include flex-width(50%);
                    }        
                }                
            }          
            @media (min-width:768px){
                &-4{
                    li{
                        @include flex-width(33.333%);                        
                    }        
                }                
            }
            
            @media (min-width:1024px){  
                &-4{
                    margin-right: -25px;
                    margin-left: -25px;
                    li{
                        @include flex-width(25%);  
                        padding-left:5*$pad;
                        padding-right:5*$pad;
                        padding-bottom:6*$pad;              
                    }        
                }                        
            }
        }

        .flip-card {
            .flip-card-inner {        
                .flip-card-front { 
                    img{
                        -o-object-fit: cover;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        max-height: 370px;
                    }            
                }
                  
                .flip-card-back {        
                    h3{
                        @include font-size(22px, 16px); 
                    }
                    h4{                        
                        @include font-size(18px, 16px);                     
                    }
                }
            }
        
            &:hover{
                .flip-card-inner {
                    @include rotateY(180deg);
                  }
            }
        }
    }
}
  
 
  
  