.testimonials{
    margin-bottom: 0;     
    padding:20px 0px 60px;
    margin-bottom: 10px; 
    border-top:1px solid $lgrey;

    header{
        h2{
            margin-bottom: 25px;
        }
    }    

    .client-info{
        display: table;

        figure{
            overflow: hidden;
            background-color: var(--main-white-color);            
            float: left;
            width: 100px;
            height: 100px;
            position: relative;
            @include border-radius(50%);
            border: 5px solid $lmgrey;            
        }
        main{
            display: table;
            width: auto;
            padding: 0 0 0 20px;

            .name{
                color: var(--main-primary-color);
                font-style: italic;
                padding-left: 40px;

                &:after{
                    content: "";
                    width: 30px;
                    height: 3px;
                    background-color: var(--main-primary-color);
                    position: absolute;
                    top: 9px;
                    left: 0;            
                } 
            }
            .designation{
                display: block;
                font-weight: 300;
                color: $grey;
                font-size: 14px;
                padding-left: 40px;
            }
        }
    }

    .owl-theme {
        .owl-nav{
            &.disabled+.owl-dots {                
                bottom: -60px;   
                
                .owl-dot {
                    span {
                        width: 30px;
                        height: 8px;
                        background: $lgrey;
                        @include box-shadow(0 0 15px 0 rgba(#000, 0));
                    }
                    &.active{
                        span {
                            width: 36px;
                            background: var(--main-hover-color);
                        }
                    }
                }
            }
        }
    }

    @include media(768px, min){
        .client-info{
            figure{                
                width: 150px;
                height: 150px;                           
            }            
        }
    }

    @include media(992px, min){
        float: left;
        width: 50%;
        margin-bottom: 0;
        margin-left: -1px;
        padding:0 0 60px 40px;
        border-left:2px solid $lgrey;
        border-top: 0px;

        .client-info{
            display: table;
    
            figure{                
                width: 200px;
                height: 200px;                            
            }
            main{                
                padding: 0 0 0 30px;
            }
        }
    }
}