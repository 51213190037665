.list-item{
    ul{
        @include padding(0px);
        @include margin(0px);
        list-style: none;

        li{
            @include margin-all(0px, 0px, 10px, 0px);
            @include padding(0px);
            position: relative;           
        }
    }    
}

.underline-list{
    ul{
        @include padding(0px);
        @include margin(0px);
        list-style: none;

        li{
            @include margin-all (0px, 0px, 15px, 0px);
            @include padding-all(0px, 0px, 15px, 0px);
            position: relative;
            border-bottom:1px solid $grey;  
            
            &:last-child{
                border-bottom:0;
                margin-bottom:0;
                padding-bottom:0;
            }
        }
    } 
    
    &.half-list{
        ul{
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;            

            li{
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
                @include padding-all (null, 15px, null, 15px);
            }
        }
    }
}

.bullet-list{
    ul{ 
        margin:0 0 15px 0;
        padding: 0px;
        list-style: none;

        li{
           padding:0 0 0 20px;
           margin:20px 0 20px 0;        
           position: relative; 

            &:before {
                content: "";                
                position: absolute;
                left: 0px;
                top: 10px;
                z-index: 9;
                width:6px;
                height: 6px;                
                background-color: var(--main-primary-color);                
            }
        }
    }  
}

